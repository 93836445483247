import { Injectable } from "@angular/core";
import { eapiakvRxStompService } from "./eapiakv-rx-stomp-services";
import { SMQActorService } from "./smq-actor-services";

@Injectable()
export class SMQAdminServiceBase extends SMQActorService {
    constructor(public stomp: eapiakvRxStompService) {
        super(stomp);
    }
    defaultTimeout = 30000;
    
    async AddAppUser(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.addappuser', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.addappuser.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.addappuser</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>addappuser</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>AddAppUser</Name><OriginalName>AddAppUser</OriginalName><CamelName>Add App User</CamelName><TitleName>Add App User</TitleName><DisplayName>Add App User</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>1</SortOrder><RAWValues><From>Admin</From><Message>AddAppUser</Message><To>CRUDCoordinator</To><Payload>AppUser</Payload><Response>AppUser</Response><Category>CRUD</Category><SortOrder>1</SortOrder></RAWValues></SMQMessage>
        */
    }

    async GetAppUsers(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.getappusers', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.getappusers.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.getappusers</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>getappusers</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>GetAppUsers</Name><OriginalName>GetAppUsers</OriginalName><CamelName>Get App Users</CamelName><TitleName>Get App Users</TitleName><DisplayName>Get App Users</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>1</SortOrder><RAWValues><From>Admin</From><Message>GetAppUsers</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Response>AppUser</Response><IsResponseCollection>true</IsResponseCollection><SortOrder>1</SortOrder></RAWValues></SMQMessage>
        */
    }

    async UpdateAppUser(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.updateappuser', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.updateappuser.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.updateappuser</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>updateappuser</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>UpdateAppUser</Name><OriginalName>UpdateAppUser</OriginalName><CamelName>Update App User</CamelName><TitleName>Update App User</TitleName><DisplayName>Update App User</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>1</SortOrder><RAWValues><From>Admin</From><Message>UpdateAppUser</Message><To>CRUDCoordinator</To><Payload>AppUser</Payload><Category>CRUD</Category><Response>AppUser</Response><SortOrder>1</SortOrder></RAWValues></SMQMessage>
        */
    }

    async DeleteAppUser(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.deleteappuser', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.deleteappuser.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.deleteappuser</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>deleteappuser</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>DeleteAppUser</Name><OriginalName>DeleteAppUser</OriginalName><CamelName>Delete App User</CamelName><TitleName>Delete App User</TitleName><DisplayName>Delete App User</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>1</SortOrder><RAWValues><From>Admin</From><Message>DeleteAppUser</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Payload>AppUser</Payload><SortOrder>1</SortOrder></RAWValues></SMQMessage>
        */
    }

    async AddDemo(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.adddemo', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.adddemo.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.adddemo</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>adddemo</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>AddDemo</Name><OriginalName>AddDemo</OriginalName><CamelName>Add Demo</CamelName><TitleName>Add Demo</TitleName><DisplayName>Add Demo</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>2</SortOrder><RAWValues><From>Admin</From><Message>AddDemo</Message><To>CRUDCoordinator</To><Payload>Demo</Payload><Response>Demo</Response><Category>CRUD</Category><SortOrder>2</SortOrder></RAWValues></SMQMessage>
        */
    }

    async GetDemos(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.getdemos', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.getdemos.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.getdemos</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>getdemos</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>GetDemos</Name><OriginalName>GetDemos</OriginalName><CamelName>Get Demos</CamelName><TitleName>Get Demos</TitleName><DisplayName>Get Demos</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>2</SortOrder><RAWValues><From>Admin</From><Message>GetDemos</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Response>Demo</Response><IsResponseCollection>true</IsResponseCollection><SortOrder>2</SortOrder></RAWValues></SMQMessage>
        */
    }

    async UpdateDemo(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.updatedemo', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.updatedemo.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.updatedemo</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>updatedemo</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>UpdateDemo</Name><OriginalName>UpdateDemo</OriginalName><CamelName>Update Demo</CamelName><TitleName>Update Demo</TitleName><DisplayName>Update Demo</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>2</SortOrder><RAWValues><From>Admin</From><Message>UpdateDemo</Message><To>CRUDCoordinator</To><Payload>Demo</Payload><Category>CRUD</Category><Response>Demo</Response><SortOrder>2</SortOrder></RAWValues></SMQMessage>
        */
    }

    async DeleteDemo(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.deletedemo', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.deletedemo.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.deletedemo</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>deletedemo</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>DeleteDemo</Name><OriginalName>DeleteDemo</OriginalName><CamelName>Delete Demo</CamelName><TitleName>Delete Demo</TitleName><DisplayName>Delete Demo</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>2</SortOrder><RAWValues><From>Admin</From><Message>DeleteDemo</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Payload>Demo</Payload><SortOrder>2</SortOrder></RAWValues></SMQMessage>
        */
    }

    async AddDemoContext(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.adddemocontext', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.adddemocontext.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.adddemocontext</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>adddemocontext</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>AddDemoContext</Name><OriginalName>AddDemoContext</OriginalName><CamelName>Add Demo Context</CamelName><TitleName>Add Demo Context</TitleName><DisplayName>Add Demo Context</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>3</SortOrder><RAWValues><From>Admin</From><Message>AddDemoContext</Message><To>CRUDCoordinator</To><Payload>DemoContext</Payload><Response>DemoContext</Response><Category>CRUD</Category><SortOrder>3</SortOrder></RAWValues></SMQMessage>
        */
    }

    async GetDemoContexts(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.getdemocontexts', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.getdemocontexts.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.getdemocontexts</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>getdemocontexts</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>GetDemoContexts</Name><OriginalName>GetDemoContexts</OriginalName><CamelName>Get Demo Contexts</CamelName><TitleName>Get Demo Contexts</TitleName><DisplayName>Get Demo Contexts</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>3</SortOrder><RAWValues><From>Admin</From><Message>GetDemoContexts</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Response>DemoContext</Response><IsResponseCollection>true</IsResponseCollection><SortOrder>3</SortOrder></RAWValues></SMQMessage>
        */
    }

    async UpdateDemoContext(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.updatedemocontext', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.updatedemocontext.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.updatedemocontext</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>updatedemocontext</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>UpdateDemoContext</Name><OriginalName>UpdateDemoContext</OriginalName><CamelName>Update Demo Context</CamelName><TitleName>Update Demo Context</TitleName><DisplayName>Update Demo Context</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>3</SortOrder><RAWValues><From>Admin</From><Message>UpdateDemoContext</Message><To>CRUDCoordinator</To><Payload>DemoContext</Payload><Category>CRUD</Category><Response>DemoContext</Response><SortOrder>3</SortOrder></RAWValues></SMQMessage>
        */
    }

    async DeleteDemoContext(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/adminmic/crudcoordinator.crud.admin.deletedemocontext', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>admin.deletedemocontext.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.admin.deletedemocontext</RoutingKey><ActorFromLower>admin</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>deletedemocontext</NameLower><Microphone>adminmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>DeleteDemoContext</Name><OriginalName>DeleteDemoContext</OriginalName><CamelName>Delete Demo Context</CamelName><TitleName>Delete Demo Context</TitleName><DisplayName>Delete Demo Context</DisplayName><ActorFrom>Admin</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>3</SortOrder><RAWValues><From>Admin</From><Message>DeleteDemoContext</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Payload>DemoContext</Payload><SortOrder>3</SortOrder></RAWValues></SMQMessage>
        */
    }

}
import { Injectable } from '@angular/core';
import { RxStompConfig } from '@stomp/rx-stomp';

/* Add the following your App.Module.ts
 providers[...
   {
     provide: eapiakvInjectableRxStompConfig, 
     useValue: eapiakvRxStompConfig,
   },
   {
     provide: eapiakvRxStompService,
     useFactory: rxStompServiceFactory,
    deps: [eapiakvInjectableRxStompConfig],
   },
   ...]

 To Use in component:  constructor(public guestService : SMQGuestService) {... guestService.DoSomething(); }
*/

export const eapiakvRxStompConfig: RxStompConfig = { 
  // Which server?
  brokerURL: 'wss://effortlessapi-rmq.ssot.me:15673/ws',

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    login: 'smqPublic',
    passcode: 'smqPublic',
    host: 'ej-demos-aicapture-io'
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 2500,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    console.error(new Date(), msg);
  },
};
                    
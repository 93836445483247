import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { eapiakvInjectableRxStompConfig } from './eapiakv-injectable-rx-stomp-config';

@Injectable()
export class eapiakvRxStompService extends RxStomp {
   constructor(eapiakvInjectableRxStompConfig:eapiakvInjectableRxStompConfig){
      super();
      this.configure(eapiakvInjectableRxStompConfig)
      this.activate();
   }
}

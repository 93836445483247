import { Injectable } from "@angular/core";
import { eapiakvRxStompService } from "./eapiakv-rx-stomp-services";
import { SMQActorService } from "./smq-actor-services";

@Injectable()
export class SMQGuestServiceBase extends SMQActorService {
    constructor(public stomp: eapiakvRxStompService) {
        super(stomp);
    }
    defaultTimeout = 30000;
    
    async RequestToken(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.general.guest.requesttoken', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.requesttoken.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.general.guest.requesttoken</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>requesttoken</NameLower><Microphone>guestmic</Microphone><CategoryLower>general</CategoryLower><Category>general</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>RequestToken</Name><OriginalName>RequestToken</OriginalName><CamelName>Request Token</CamelName><TitleName>Request Token</TitleName><DisplayName>Request Token</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder /><RAWValues><From>Guest</From><Message>RequestToken</Message><Category>General</Category><To>CRUDCoordinator</To><Payload>EmailAddress</Payload><HttpPOSTUrl /></RAWValues></SMQMessage>
        */
    }

    async ValidateTemporaryAccessToken(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.general.guest.validatetemporaryaccesstoken', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.validatetemporaryaccesstoken.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.general.guest.validatetemporaryaccesstoken</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>validatetemporaryaccesstoken</NameLower><Microphone>guestmic</Microphone><CategoryLower>general</CategoryLower><Category>general</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>ValidateTemporaryAccessToken</Name><OriginalName>ValidateTemporaryAccessToken</OriginalName><CamelName>Validate Temporary Access Token</CamelName><TitleName>Validate Temporary Access Token</TitleName><DisplayName>Validate Temporary Access Token</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder /><RAWValues><From>Guest</From><Message>ValidateTemporaryAccessToken</Message><To>CRUDCoordinator</To><Category>General</Category><Payload>TemporaryAccessToken</Payload><HttpPOSTUrl /></RAWValues></SMQMessage>
        */
    }

    async WhoAmI(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.general.guest.whoami', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.whoami.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.general.guest.whoami</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>whoami</NameLower><Microphone>guestmic</Microphone><CategoryLower>general</CategoryLower><Category>general</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>WhoAmI</Name><OriginalName>WhoAmI</OriginalName><CamelName>Who Am I</CamelName><TitleName>Who Am I</TitleName><DisplayName>Who Am I</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder /><RAWValues><From>Guest</From><Message>WhoAmI</Message><To>CRUDCoordinator</To><Category>General</Category><Payload>AccessToken</Payload><HttpPOSTUrl /></RAWValues></SMQMessage>
        */
    }

    async WhoAreYou(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.general.guest.whoareyou', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.whoareyou.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.general.guest.whoareyou</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>whoareyou</NameLower><Microphone>guestmic</Microphone><CategoryLower>general</CategoryLower><Category>general</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>WhoAreYou</Name><OriginalName>WhoAreYou</OriginalName><CamelName>Who Are You</CamelName><TitleName>Who Are You</TitleName><DisplayName>Who Are You</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder /><RAWValues><From>Guest</From><Message>WhoAreYou</Message><To>CRUDCoordinator</To><Category>General</Category><Payload>AccessToken</Payload><HttpPOSTUrl /></RAWValues></SMQMessage>
        */
    }

    async StoreTempFile(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.utlity.guest.storetempfile', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.storetempfile.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.utlity.guest.storetempfile</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>storetempfile</NameLower><Microphone>guestmic</Microphone><CategoryLower>utlity</CategoryLower><Category>utlity</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>StoreTempFile</Name><OriginalName>StoreTempFile</OriginalName><CamelName>Store Temp File</CamelName><TitleName>Store Temp File</TitleName><DisplayName>Store Temp File</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder /><RAWValues><From>Guest</From><Message>StoreTempFile</Message><To>CRUDCoordinator</To><Payload>General</Payload><Category>Utlity</Category><ReplyPayload>Url</ReplyPayload><HttpPOSTUrl /></RAWValues></SMQMessage>
        */
    }

    async GetDemos(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.crud.guest.getdemos', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.getdemos.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.guest.getdemos</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>getdemos</NameLower><Microphone>guestmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>GetDemos</Name><OriginalName>GetDemos</OriginalName><CamelName>Get Demos</CamelName><TitleName>Get Demos</TitleName><DisplayName>Get Demos</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>2</SortOrder><RAWValues><From>Guest</From><Message>GetDemos</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Response>Demo</Response><IsResponseCollection>true</IsResponseCollection><SortOrder>2</SortOrder></RAWValues></SMQMessage>
        */
    }

    async GetDemoContexts(payload: any = null, timeoutMS : number = this.defaultTimeout) {
        return this.sendPayload('/exchange/guestmic/crudcoordinator.crud.guest.getdemocontexts', payload, timeoutMS);
        /*
        <SMQMessage><SMQMessageKey>guest.getdemocontexts.crudcoordinator</SMQMessageKey><RoutingKey>crudcoordinator.crud.guest.getdemocontexts</RoutingKey><ActorFromLower>guest</ActorFromLower><ActorToLower>crudcoordinator</ActorToLower><NameLower>getdemocontexts</NameLower><Microphone>guestmic</Microphone><CategoryLower>crud</CategoryLower><Category>crud</Category><SamplePayload></SamplePayload><SampleResponse></SampleResponse><Name>GetDemoContexts</Name><OriginalName>GetDemoContexts</OriginalName><CamelName>Get Demo Contexts</CamelName><TitleName>Get Demo Contexts</TitleName><DisplayName>Get Demo Contexts</DisplayName><ActorFrom>Guest</ActorFrom><ActorTo>CRUDCoordinator</ActorTo><Description /><IsDirectMessage /><SortOrder>3</SortOrder><RAWValues><From>Guest</From><Message>GetDemoContexts</Message><To>CRUDCoordinator</To><Category>CRUD</Category><Response>DemoContext</Response><IsResponseCollection>true</IsResponseCollection><SortOrder>3</SortOrder></RAWValues></SMQMessage>
        */
    }

}
// import { RxStompService } from "@stomp/ng2-stompjs";
import { Observable, ReplaySubject } from "rxjs";
import { eapiakvRxStompService } from "./eapiakv-rx-stomp-services";

export class SMQActorService {
    public isReady: Observable<any>;
    client: any;
    accessToken: string;
    waitingForIds: any = {};
    waitingForTimeouts: any = {};

    constructor(public stomp: eapiakvRxStompService) {
        console.error('INITIALIZEING SMQ ACTOR SERVICE: ', this, stomp);
        this.isReady = stomp.connected$;
        this.accessToken = "";
        this.handleWaitingForReplies();
    }

    private handleWaitingForReplies() {
        this.stomp.unhandledMessage$.subscribe((frame) => {
            var correlationId = frame.headers['correlation-id'];
            var payload = JSON.parse(frame.body);
            if (payload.IsHandled) {
                if (this.waitingForTimeouts[correlationId]) {
                    clearTimeout(this.waitingForTimeouts[correlationId]);
                    delete this.waitingForTimeouts[correlationId];
                }

                if (this.waitingForIds[correlationId]) {
                    var callback = this.waitingForIds[correlationId];
                    delete this.waitingForIds[correlationId];
                    callback(frame);
                }
            }
        });
    }

    createPayload() {
        return { AccessToken: this.accessToken };
    }

    async sendPayload(destination: string, payload: any, timeoutMS) {
        var correlationId = this.createUUID();

        this.stomp.publish({
            destination: destination,
            headers: {
                "content-type": "application/json",
                "reply-to": "/temp-queue/response-queue",
                "correlation-id": correlationId
            },
            body: JSON.stringify(payload || this.createPayload())
        });
        return await this.waitForId(destination, correlationId, timeoutMS);
    }


    createUUID = function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    waitForId(methodName: string, correlationId: string, timeoutMS: number) {
        return new Promise((accept, reject) => {
            this.waitingForIds[correlationId] = (frame) => {
                var payload: any = JSON.parse(frame.body)
                payload._smqFrame = frame;
                accept(payload);
            };
            this.waitingForTimeouts[correlationId] = setTimeout(() => {
                if (this.waitingForIds[correlationId]) {
                    delete this.waitingForIds[correlationId];
                    reject(`Timed out waiting for a response to: ${methodName}`);
                }
            }, timeoutMS);
        });
    }
}

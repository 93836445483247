<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><b>AI Capture</b> demos</a>
  </div>
</div>

<div class="header-container">
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>

  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
  </nb-actions>
</div>
